import React from 'react'
import T from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  withStyles,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const AssessmentTool = ({
  theme,
  classes,
  assessmentToolMeta,
  barColor,
  isAuthenticated,
}) => {
  const { t } = useTranslation()
  return (
    <Grid item xs data-testid="assessment-tool">
      <Card className={classes.card} style={{ borderTopColor: barColor }}>
        <CardContent className={classes.cardContent}>
          <Grid container justify="flex-start" spacing={0}>
            <Grid item>
              <Img fixed={assessmentToolMeta.logo.childImageSharp.fixed} />
            </Grid>
            <Grid item className={classes.assessmentName}>
              <Typography variant="h2">{t(assessmentToolMeta.name)}</Typography>
            </Grid>
          </Grid>
          <Typography className={classes.cardElement}>
            {t(assessmentToolMeta.shortDescription)}
          </Typography>
        </CardContent>
        <CardActions className={classes.bottom}>
          <Grid
            container
            justify="space-between"
            alignItems="flex-end"
            className={classes.bottomContainer}
          >
            <Grid item>
              {/* Hidden until find out more page available */}
              {/* <Button
                className={classes.firstButton}
                component={Link}
                to={`/assessment/${assessmentToolMeta.key}`}
                color="secondary"
                fullWidth
              >
                {t('find out more')}
              </Button> */}
            </Grid>
            {isAuthenticated && (
              <Grid item>
                <Button
                  className={classes.secondButton}
                  component={Link}
                  to={`/assessment/${assessmentToolMeta.key}`}
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  {t('get started')}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  )
}

const styles = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderTopWidth: theme.spacing(1),
    borderTopStyle: 'solid',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    flex: 1,
  },
  cardTitle: {
    marginLeft: theme.spacing(2),
  },
  assessmentName: {
    marginTop: '0.5em',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
  bottomContainer: {
    margin: theme.spacing(1),
  },
  firstButton: {
    paddingLeft: '0px',
  },
  secondButton: {
    paddingLeft: '48px',
    paddingRight: '48px',
  },
})

AssessmentTool.propTypes = {
  assessmentToolMeta: T.shape({
    name: T.string.isRequired,
    shortDescription: T.string.isRequired,
    logo: T.object.isRequired,
  }).isRequired,
  barColor: T.string.isRequired,
  isAuthenticated: T.bool.isRequired,
}

export default withStyles(styles, { withTheme: true })(AssessmentTool)
