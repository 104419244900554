import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import { Grid, Paper, Typography, Button, withStyles } from '@material-ui/core'
import {
  AuthContext,
  PaddedContainer,
  SectionTitle,
  ActionsTable,
} from 'gatsby-components'
import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'
import { getAssessmentTypes } from 'efqm-theme/assessments/getAssessmentParts'
import Img from 'gatsby-image'
import { get } from 'lodash'

import AssessmentsTable from '../components/AssessmentsTable'
import AssessmentTool from '../components/AssessmentTool'
import LensTool from '../components/LensTool'
import SEO from '../components/SEO'

const assessmentColors = [
  theme => theme.palette.primary.light,
  theme => theme.palette.secondary.light,
  theme => theme.palette.primary.main,
]

const lensColors = {
  'topical lens': theme => theme.palette.secondary.main,
  'sector lens': () => '#2165e2',
  'Hamdam foundation': () => '#2165e2',
}

function AssessmentsHome({ theme, classes, data, pageContext = {} }) {
  const {
    heroBanner,
    modelImageAssets: { nodes: modelImageAssets },
    assets: { nodes: assets },
    lensLogoAssets: { nodes: lensLogoAssets },
  } = data

  const { modelImageName, lensTypeKey } = pageContext

  const { t, i18n } = useTranslation()
  const lang = i18n.language || 'en'

  const modelImage =
    modelImageAssets.find(img => img.name === `${modelImageName}_${lang}`) ||
    modelImageAssets.find(img => img.name === `${modelImageName}_en`)

  const assessmentTypes = getAssessmentTypes(i18n)
  const { getUserTokenData } = useContext(AuthContext)
  const { isAuthenticated, isPlatformAdmin, isAdmin } = getUserTokenData()

  const assessmentDefinitions = assessmentTypes
    .map(type => ({
      ...type,
      logo: assets.find(asset => asset.name === type.logoAsset),
    }))
    .filter(type => type.orderIndex > 0 && type.logo && !type.hideOnHomePage)
    .sort((a, b) => a.orderIndex - b.orderIndex)

  const assessmentItems = assessmentDefinitions.filter(
    def => def.orderIndex > 0 && def.logo && def.type === 'assessment'
  )
  const lensItems = assessmentDefinitions.filter(
    def => def.orderIndex > 0 && def.logo && def.type === 'lens'
  )
  const availableLens = lensItems.filter(item => !item.comingSoon)

  const backgroundImageRightToLeftFlipImage =
    // this method is the only way to guarantee the transform animation triggers on lang change, doesnt work via classnames
    theme.direction === 'rtl'
      ? {
          '-webkit-transform': 'scaleX(-1)',
          transform: 'scaleX(-1)',
        }
      : undefined

  return (
    <BackgroundImage
      className={classes.heroContainer}
      style={backgroundImageRightToLeftFlipImage}
      fluid={heroBanner.childImageSharp.fluid}
    >
      {/* flipping BackgroundImage flips all children, so this div container flips it back */}
      <div style={backgroundImageRightToLeftFlipImage}>
        <SEO title="Assessment Home Page" />
        <div className={classes.header}>
          <PaddedContainer className={classes.heroDescription}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={7}>
                <Grid container spacing={3} direction="column">
                  <Grid item xs={6}>
                    <Typography variant="h1">
                      {t('Welcome to the EFQM AssessBase')}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {t(
                        'The AssessBase is a unique assessment platform to support your organisation in its journey towards outstanding performance, cultural change and transformation.'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5}>
                {modelImage && (
                  <Img
                    className={classes.modelContainer}
                    fluid={modelImage.childImageSharp.fluid}
                  />
                )}
              </Grid>
            </Grid>
          </PaddedContainer>
        </div>
        <PaddedContainer>
          <div className={classes.sectionTop}>
            {isAuthenticated && (
              <>
                <Grid
                  container
                  justify="space-between"
                  alignItems="flex-end"
                  className={classes.tableHeader}
                >
                  <Grid item>
                    <Typography variant="h1">
                      {isPlatformAdmin ? t('Actions') : t('Assessments')}
                    </Typography>
                    <Typography variant="h3">{t('Most recent')}</Typography>
                  </Grid>
                  <Grid item>
                    {isPlatformAdmin && (
                      <Button
                        className={classes.adminButton}
                        component={Link}
                        to="/admin/actions"
                        color="secondary"
                        variant="contained"
                        fullWidth
                      >
                        {t('Go to admin portal')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Paper>
                  {isPlatformAdmin ? (
                    <ActionsTable pageSizes={[5]} />
                  ) : (
                    <AssessmentsTable isAdmin={isAdmin} />
                  )}
                </Paper>
              </>
            )}
          </div>
          <Grid item xs={3} className={classes.toolHeader}>
            <SectionTitle barColor={theme.palette.primary.dark} noWrap>
              {t('Start a new assessment')}
            </SectionTitle>
          </Grid>
          <div className={classes.sectionBottom}>
            <Grid container spacing={4} wrap="nowrap">
              {assessmentItems.map((item, index) => (
                <AssessmentTool
                  key={`${item.name}_${index}`}
                  assessmentToolMeta={item}
                  barColor={assessmentColors[index % assessmentColors.length](
                    theme
                  )}
                  isAuthenticated={item.nonUserAllowed || isAdmin}
                />
              ))}
            </Grid>
          </div>
          <Grid item xs={3} className={classes.toolHeader}>
            <SectionTitle barColor={theme.palette.primary.dark} noWrap>
              {t('Start a new lens')}
            </SectionTitle>
          </Grid>
          <div className={classes.sectionBottom}>
            <Grid container spacing={4}>
              {availableLens.map((item, index) => (
                <LensTool
                  key={`${item.name}_${index}`}
                  lensToolMeta={item}
                  barColor={lensColors[lensTypeKey[item.key]](theme)}
                  isAuthenticated={isAuthenticated}
                  assets={assets}
                  assessmentItems={assessmentItems}
                  logoImg={get(
                    lensLogoAssets.find(
                      ({ name }) => name === item.lensToolLogoImageName
                    ),
                    'childImageSharp.fixed'
                  )}
                />
              ))}
            </Grid>
          </div>
        </PaddedContainer>
      </div>
    </BackgroundImage>
  )
}

const styles = theme => ({
  header: {
    position: 'relative',
    marginBottom: theme.spacing(5),
  },
  heroContainer: {
    backgroundSize: '100%',
    backgroundPosition: 'top left',
  },
  heroDescription: {
    marginTop: theme.spacing(1),
  },
  modelContainer: {
    marginTop: theme.spacing(4),
    maxHeight: '429px',
    maxWidth: '443px',
    left: theme.spacing(10),
  },
  sectionTop: {
    marginTop: theme.spacing(2),
  },
  toolHeader: {
    marginTop: theme.spacing(5),
  },
  tableHeader: {
    marginBottom: theme.spacing(3),
    '& .MuiTypography-h3': {
      color: '#59607d',
      marginTop: theme.spacing(0.5),
    },
  },
  adminButton: {
    padding: theme.spacing(1, 3),
  },
  sectionBottom: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
})

export const query = graphql`
  query HomeTemplateQuery(
    $heroImageName: String!
    $modelImageAssets: [String]!
    $assets: [String]!
    $lensLogoAssets: [String]!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    assessmentTypes: allAssessments {
      nodes {
        orderIndex
        name
        key
        logoAsset
        shortDescription
      }
    }
    heroBanner: file(name: { eq: $heroImageName }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    modelImageAssets: allFile(filter: { name: { in: $modelImageAssets } }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    assets: allFile(filter: { name: { in: $assets } }) {
      nodes {
        name
        childImageSharp {
          fixed(width: 40, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    lensLogoAssets: allFile(filter: { name: { in: $lensLogoAssets } }) {
      nodes {
        name
        childImageSharp {
          fixed(height: 140, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default withStyles(styles, { withTheme: true })(AssessmentsHome)
