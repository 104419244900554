import React from 'react'
import T from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  withStyles,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

const styles = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderTopWidth: theme.spacing(1),
    borderTopStyle: 'solid',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    flex: 1,
    paddingBottom: '0px !important',
  },
  cardTitle: {
    marginBottom: theme.spacing(0.25),
  },
  disabled: {
    opacity: 0.2,
  },
  assessmentLinks: {
    margin: theme.spacing(1.5, 1, 1.5, 1.5),
    borderRadius: '5px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.light,
    },
  },
  assessmentImage: {
    margin: theme.spacing(0.5, 0, 0.5, 1),
  },
  assessmentName: {
    marginLeft: theme.spacing(2),
    '& .MuiTypography-h3': {
      color: theme.palette.primary.dark,
    },
  },
  assessmentTitleSection: {
    marginBottom: theme.spacing(2),
  },
  assessmentLinkPaper: {
    marginRight: theme.spacing(2),
  },
  name: {
    color: theme.palette.primary.main,
  },
  lensType: {
    color: theme.palette.primary.dark,
    fontSize: 10,
    marginTop: theme.spacing(0.5),
  },
  firstButton: {
    paddingLeft: '0px',
  },
  secondButton: {
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  bottom: {
    paddingTop: 0,
  },
})

const LensToolCard = withStyles(styles)(
  ({ classes, barColor, CardActionsElement, CardContentElement }) => (
    <Grid item xs={6} data-testid="assessment-tool">
      <Card className={classes.card} style={{ borderTopColor: barColor }}>
        <CardContent className={classes.cardContent}>
          {CardContentElement}
        </CardContent>
        <CardActions className={classes.bottom}>
          {CardActionsElement}
        </CardActions>
      </Card>
    </Grid>
  )
)

const LensToolHeader = withStyles(styles, { withTheme: true })(
  ({ classes, lensToolMeta }) => {
    const { t } = useTranslation()

    return (
      <>
        <Grid item>
          <Img fixed={lensToolMeta.logo.childImageSharp.fixed} />
        </Grid>
        <Grid item className={classes.assessmentName}>
          <div className={classes.assessmentTitleSection}>
            <Typography variant="h2" className={classes.name}>
              {t(
                !lensToolMeta.comingSoon
                  ? lensToolMeta.name
                  : lensToolMeta.comingSoon
              )}
            </Typography>
            <Typography variant="h4" className={classes.lensType}>
              {t(lensToolMeta.lensType)}
            </Typography>
          </div>
        </Grid>
      </>
    )
  }
)

const LensToolComingSoon = withStyles(styles, { withTheme: true })(
  ({ classes, lensToolMeta, barColor }) => (
    <LensToolCard
      barColor={barColor}
      CardContentElement={
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          spacing={0}
          className={classes.cardTitle}
        >
          <LensToolHeader lensToolMeta={lensToolMeta} />
        </Grid>
      }
    />
  )
)

const LensTool = withStyles(styles, { withTheme: true })(
  ({
    classes,
    lensToolMeta,
    barColor,
    assessmentItems,
    isAuthenticated,
    logoImg,
  }) => {
    const { t } = useTranslation()

    const hasLogo = !!logoImg

    return (
      <LensToolCard
        barColor={barColor}
        CardContentElement={
          <>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing={0}
              className={classes.cardTitle}
            >
              <Grid
                item
                container
                xs={hasLogo ? 6 : 12}
                justify="flex-start"
                spacing={0}
                className={classes.cardTitle}
              >
                <LensToolHeader lensToolMeta={lensToolMeta} />
                <Grid item xs={12}>
                  <Typography className={classes.cardElement}>
                    {t(lensToolMeta.shortDescription)}
                  </Typography>
                </Grid>
              </Grid>
              {hasLogo && (
                <Grid item container justify="center" xs={6}>
                  <Grid item>
                    <Img fixed={logoImg} />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container item justify="space-between" alignItems="flex-end">
              <Grid item>
                {lensToolMeta.findOutMore && (
                  <Button
                    className={classes.firstButton}
                    component={Link}
                    to={`/find-out-more/${lensToolMeta.key}`}
                    color="secondary"
                    fullWidth
                  >
                    {t('find out more')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </>
        }
        CardActionsElement={
          <Grid container spacing={1}>
            {lensToolMeta.assessmentTypes.map((type, i) => {
              const assessment = assessmentItems.find(
                item => item.key === type.lensVersionOf
              )
              const disableAssessment =
                type.comingSoon || (!isAuthenticated && !type.nonUserAllowed)
              const key = `${type.key}_${type.lensVersionOf}`

              const output = (
                <Grid
                  container
                  item
                  justify="flex-start"
                  alignItems="center"
                  className={classnames(classes.assessmentLinks, {
                    [classes.disabled]: disableAssessment,
                  })}
                  key={key}
                >
                  <Img
                    fixed={assessment.logo.childImageSharp.fixed}
                    className={classes.assessmentImage}
                  />
                  <div className={classes.assessmentName}>
                    <Typography variant="h3">
                      {t(assessment.lensVersionName)}
                    </Typography>
                    <Typography variant="h4" className={classes.lensType}>
                      {t('for')}&nbsp;{lensToolMeta.name}
                    </Typography>
                  </div>
                </Grid>
              )
              // to remove linking behaviour if coming soon, gatsby links does not allow for disabled
              return disableAssessment ? (
                <div className={classes.assessmentLinkPaper}>{output}</div>
              ) : (
                <Link
                  to={`/${type.type}/${type.key}`}
                  key={`${key}_link`}
                  className={classes.assessmentLinkPaper}
                >
                  {output}
                </Link>
              )
            })}
          </Grid>
        }
      />
    )
  }
)

LensTool.propTypes = {
  lensToolMeta: T.shape({
    name: T.string.isRequired,
    shortDescription: T.string.isRequired,
    logo: T.object.isRequired,
  }).isRequired,
  barColor: T.string.isRequired,
  assessmentItems: T.array.isRequired,
  isAuthenticated: T.bool,
  logoImg: T.object,
}

export default LensTool
export { LensToolComingSoon }
